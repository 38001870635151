import axios from 'src/_services/axios-configs'

const state = {
  userProfile: JSON.parse(localStorage.getItem('userProfile')) ? JSON.parse(localStorage.getItem('userProfile')) : null
}

const getters = {
  userProfile (state) {
    return state.userProfile
  }
}

const mutations = {
  setUserProfile (state, userProfile) {
    state.userProfile = userProfile
  }
}

const actions = {
  setUserProfile ({ commit }, userProfile) {
    commit('setUserProfile', userProfile)
  },
  getUserProfile ({ dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/user/profile')
        .then((res) => {
          dispatch('setUserProfile', res.data)
          if (localStorage.getItem('userProfile')) localStorage.removeItem('userProfile')
          localStorage.setItem('userProfile', JSON.stringify(res.data))
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  editUserProfile (_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/user/profile', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  hideAnonymousId(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/anon_caller/user/anon_caller_id/${data}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  hideSmsToEmail(_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/sms_to_email/enable/${data}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  donotDisturb (_, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/do_not_disturb/${data}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  createPayment (_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/user/create_payment', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getPlanList () {
    return new Promise((resolve, reject) => {
      axios
        .get('plan/list')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getSubscriptions () {
    return new Promise((resolve, reject) => {
      axios
        .get('user/subscriptions')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  upgradePlan (_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('plan/user/upgrade_plan', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  downgradePlan () {
    return new Promise((resolve, reject) => {
      axios
        .post('plan/user/downgrade_plan')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  autoRenewCard (_, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('user/auto_charge/card', data)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  autoChargeCredits (_, { enable, threshole }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/auto_charge_credits/enable/${enable}`, {}, { params: { credit_threshole: threshole } })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  autoRenew (_, trigger) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/user/auto_renew_plan/enable/${trigger}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getAutoChargeCards () {
    return new Promise((resolve, reject) => {
      axios
        .get('/user/auto_charge/card')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  switchScreenCall (_, trigger) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`user/call_screening/${trigger}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  switchVoicemailTranscripts (_, trigger) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`user/vm_transcrib/${trigger}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  removeCallHistory () {
    return new Promise((resolve, reject) => {
      axios
        .delete('logging/call/remove_history')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getBillingHistory (_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('user/transaction_log/list', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getVoicemailsList (_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('logging/voicemail/list/all', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getRuleList (_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('/rules', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getRuleByUuid (_, uuid) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/rules/${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  postRule (_, { numberType, body }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/rules/${numberType}`, body)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  patchRule (_, { uuid, body }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/rules/${uuid}`, body)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deleteRule (_, uuid) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/rules/${uuid}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getPriceList (_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('/pricing/common', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getSMSList (_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('logging/sms/list/all', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getChatList (_, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get('logging/sms/chat/list', { params })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  deactivateUser () {
    return new Promise((resolve, reject) => {
      axios
        .post('/user/deactivate')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  setLowBalanceEmail (_, trigger) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/user/low_balance_email/enable/${trigger}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  },
  getUserSessions () {
    return new Promise((resolve, reject) => {
      axios
        .get('/user/sessions')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.response.data)
        })
    })
  }
}

export default { state, getters, mutations, actions }
